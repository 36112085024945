var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Container, Point, Text } from "pixi.js";
import Game from "../starter";
import { ActionsContainer, BaseScreen, CONSTANTS, Helpers, MESSAGES, } from "lama-games-engine";
import { Actions } from "../ui/Actions";
import { BonusMultiplier } from '../ui/BonusMultiplier';
import { ScaleContainer, WrapperContainer } from "../ui/Containers";
import { ReelsContainer } from "../ui/ReelsContainer";
import { Transformers } from "../utilities/transformers";
import { WinningLines } from "../ui/WinningLines";
import { WinModal } from "../ui/WinModal";
const BACKGROUND_ORIG_WIDTH = 1100;
const BACKGROUND_ORIG_HEIGHT = 1100;
/* Loads and presents a loading screen of the game */
function hideAddressBar() {
    if (!window.location.hash) {
        // @ts-ignore
        if (document.height < window.outerHeight)
            document.body.style.height = (window.outerHeight + 50) + 'px';
        setTimeout(function () {
            window.scrollTo(0, 1);
            document.body.style.height = 'auto';
        }, 500);
    }
}
export class GameScreen extends BaseScreen {
    constructor() {
        super();
        this.insideContainer = new WrapperContainer();
        this.scale = 1;
        this.container = new ScaleContainer();
        this.insideContainer.sortableChildren = true;
        this.reelsContainer = new ReelsContainer();
        this.insideContainer.addChild(this.reelsContainer);
        this.container.addChild(this.insideContainer);
        if (window.PLATFORM.isMobile) {
            if (window.PLATFORM.isLandscape) {
                this.height = this.container.width;
                this.width = this.container.height;
            }
            if (window.PLATFORM.isPortrait) {
                this.height = this.container.height;
                this.width = this.container.width;
            }
        }
        else {
            this.width = this.container.width;
            this.height = this.container.height;
        }
        this.scale = this.getScale();
        this.container.name = "ScaleContainer";
        this.container.scale.set(this.scale);
        this.reelsContainer.pivot.set(this.reelsContainer.width / 2, this.reelsContainer.height / 2);
        this.positionReelsContainer();
        this.createScreen();
        this.loadBackground();
        this.loadLogo();
        this.gameIdLabel = new Text('', {
            fill: 'white',
            fontSize: 26
        });
        this.prepareGameIdLabel();
        /* Use more space on landscape mobile */
        setTimeout(() => {
            if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
                hideAddressBar();
            }
        }, 1000);
        this.registerEventListeners();
    }
    prepareGameIdLabel() {
        this.gameIdLabel.zIndex = 1000;
        this.gameIdLabel.anchor.set(0.5);
        this.insideContainer.addChild(this.gameIdLabel);
        this.gameIdLabel.alpha = 0.3;
        this.positionGameIdLabel();
    }
    positionGameIdLabel() {
        this.gameIdLabel.y = (window.innerHeight / this.getScale() - this.gameIdLabel.height / 2);
        this.gameIdLabel.x = (window.innerWidth / 2) / this.getScale();
        if (window.PLATFORM.isMobile) {
            this.gameIdLabel.style.fontSize = 38;
        }
        else {
            this.gameIdLabel.style.fontSize = 26;
        }
    }
    loadBackground() {
        this.background = Helpers.getSpriteFromTextureURL(`/videos/video.mp4`);
        this.background.anchor.set(0.5);
        this.positionBackground();
        // @ts-ignore
        this.background._texture.baseTexture.resource["source"].loop = true;
        // @ts-ignore
        this.background._texture.baseTexture.resource["source"].muted = true;
        this.insideContainer.addChild(this.background);
    }
    positionBackground() {
        const { width, height } = this.windowSize();
        const diagonal = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
        const scale = Math.max(diagonal / BACKGROUND_ORIG_WIDTH, diagonal / BACKGROUND_ORIG_HEIGHT);
        this.background.position.set(width / 2 / this.scale, height / 2 / this.scale);
        this.background.scale.set(scale / this.scale);
    }
    loadLogo() {
        this.logo = Helpers.animatedSpriteFromFrames('/textures/logo-egypt.json');
        this.logo.loop = true;
        this.logo.animationSpeed = 0.3;
        this.logo.gotoAndPlay(0);
        this.logo.eventMode = 'static';
        this.positionLogo();
        this.logo.zIndex = 3000;
        this.insideContainer.addChild(this.logo);
    }
    positionLogo() {
        const reelsPosition = this.reelsContainer.getGlobalPosition();
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            // const spinButtonPosition = this.ActionsWrapper.spinButton.button.sprite.getGlobalPosition()
            const x = 
            // (this.windowSize().width / 2) / this.scale
            this.logo.width / 2;
            const y = 0;
            // Set the anchor point to the center bottom of the logo
            this.logo.anchor.set(0.5, 0);
            this.logo.position.set(x, y);
            this.logo.scale.set(0.6);
            return;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            // Center relative to reels container
            const x = (this.windowSize().width / 2) / this.scale;
            // Position right on top of the reels container
            const y = 0;
            // Set the anchor point to the center bottom of the logo
            this.logo.anchor.set(0.5, 0);
            this.logo.position.set(x, y);
            this.logo.scale.set(1);
            return;
        }
        // Center relative to reels container
        const x = (this.windowSize().width / 2) / this.scale;
        // Position right on top of the reels container
        const y = reelsPosition.y - this.logo.height / this.scale;
        // Set the anchor point to the center bottom of the logo
        this.logo.anchor.set(0.5, 0);
        this.logo.position.set(x, y);
        this.logo.scale.set(0.6);
    }
    createScreen() {
        Game.Engine.ACTIONS_CONTAINER = new ActionsContainer();
        this.insideContainer.addChild(Game.Engine.ACTIONS_CONTAINER.container);
        Game.Engine.ACTIONS_CONTAINER.container.zIndex = 2000;
        this.ActionsWrapper = new Actions();
        Game.Engine.ACTIONS_CONTAINER.container.addChild(this.ActionsWrapper.actionsContainer);
        this.insideContainer.addChild(this.ActionsWrapper.sliderContainer);
        this.ActionsWrapper.sliderContainer.zIndex = 1000;
        this.positionActions();
        this.positionActionsContainer();
        return;
    }
    positionActions() {
        const actionsMargin = 0;
        const windowSize = {
            width: this.windowSize().width / this.scale,
            height: this.windowSize().height / this.scale
        };
        this.ActionsWrapper.spinButton.button.sprite.scale.set(3.5);
        this.ActionsWrapper.spinButton.button.sprite.x = windowSize.width / 2;
        this.ActionsWrapper.autospinButton.button.sprite.x = this.ActionsWrapper.spinButton.button.sprite.x
            - (this.ActionsWrapper.spinButton.button.sprite.width / 2)
            - (actionsMargin * 2);
        this.ActionsWrapper.autospinButton.button.sprite.y = this.ActionsWrapper.spinButton.button.sprite.y
            + (this.ActionsWrapper.spinButton.button.sprite.height / 2)
            - (this.ActionsWrapper.autospinButton.button.sprite.height / 2);
        this.ActionsWrapper.speedContainer.x = this.ActionsWrapper.spinButton.button.sprite.x
            + (this.ActionsWrapper.spinButton.button.sprite.width / 2)
            // + (this.ActionsWrapper.speedContainer.width / 2)
            + (actionsMargin * 2);
        this.ActionsWrapper.speedContainer.y = this.ActionsWrapper.spinButton.button.sprite.y
            + (this.ActionsWrapper.spinButton.button.sprite.height / 2)
            - (this.ActionsWrapper.speedContainer.height / 2);
    }
    positionActionsContainer() {
        if (window.PLATFORM.isMobile && window.PLATFORM.isLandscape) {
            Game.Engine.ACTIONS_CONTAINER.container.scale.set(0.62);
            Game.Engine.ACTIONS_CONTAINER.container.pivot.x = (this.windowSize().width / 2) / this.scale;
            Game.Engine.ACTIONS_CONTAINER.container.x = (this.windowSize().width) / this.scale - (Game.Engine.ACTIONS_CONTAINER.container.width / 2) - 300;
            Game.Engine.ACTIONS_CONTAINER.container.y = ((this.windowSize().height) / this.scale) - Game.Engine.ACTIONS_CONTAINER.container.height;
            /* Position Slide Container or container with win bet and plus actions */
            const spinButtonPosition = this.ActionsWrapper.spinButton.button.sprite.getGlobalPosition();
            this.ActionsWrapper.sliderContainer.position.x =
                spinButtonPosition.x / this.scale;
            this.ActionsWrapper.sliderContainer.scale.set(0.62);
            this.ActionsWrapper.sliderContainer.position.y =
                spinButtonPosition.y / this.scale
                    - this.ActionsWrapper.sliderContainer.height;
            return;
        }
        if (window.PLATFORM.isMobile && window.PLATFORM.isPortrait) {
            Game.Engine.ACTIONS_CONTAINER.container.scale.set(1.3);
            Game.Engine.ACTIONS_CONTAINER.container.pivot.x = (this.windowSize().width / 2) / this.scale;
            Game.Engine.ACTIONS_CONTAINER.container.x = (this.windowSize().width / 2) / this.scale;
            Game.Engine.ACTIONS_CONTAINER.container.y = ((this.windowSize().height) / this.scale) - Game.Engine.ACTIONS_CONTAINER.container.height;
            /* Position Slide Container or container with win bet and plus actions */
            this.ActionsWrapper.sliderContainer.scale.set(1.3);
            this.ActionsWrapper.sliderContainer.position.x = (this.windowSize().width / 2) / this.scale;
            this.ActionsWrapper.sliderContainer.position.y = Game.Engine.ACTIONS_CONTAINER.container.y;
            return;
        }
        Game.Engine.ACTIONS_CONTAINER.container.pivot.x = (this.windowSize().width / 2) / this.scale;
        Game.Engine.ACTIONS_CONTAINER.container.x = (this.windowSize().width / 2) / this.scale;
        // Game.Engine.ACTIONS_CONTAINER.container.y = ((this.windowSize().height) / this.scale) - Game.Engine.ACTIONS_CONTAINER.container.height
        Game.Engine.ACTIONS_CONTAINER.container.y = this.reelsContainer.y + (this.reelsContainer.height / 2) - (Game.Engine.ACTIONS_CONTAINER.container.height / 4);
        Game.Engine.ACTIONS_CONTAINER.container.scale.set(0.5);
        /* Position Slide Container or container with win bet and plus actions */
        this.ActionsWrapper.sliderContainer.position.x = (this.windowSize().width / 2) / this.scale;
        this.ActionsWrapper.sliderContainer.position.y = Game.Engine.ACTIONS_CONTAINER.container.y;
        this.ActionsWrapper.sliderContainer.zIndex = 2000;
        this.ActionsWrapper.sliderContainer.scale.set(0.5);
        // Game.Engine.ACTIONS_CONTAINER.
    }
    positionReelsContainer() {
        if (window.PLATFORM.isMobile) {
            if (window.PLATFORM.isPortrait) {
                this.reelsContainer.scale.set(1.35);
                const centerScreenPoint = new Point((this.windowSize().width / 2) / this.scale, (this.windowSize().height / 2) / this.scale);
                this.reelsContainer.x = (centerScreenPoint.x);
                this.reelsContainer.y = this.reelsContainer.height;
            }
            if (window.PLATFORM.isLandscape) {
                this.reelsContainer.scale.set(0.8);
                const centerScreenPoint = new Point((this.windowSize().width / 2) / this.scale, (this.windowSize().height / 2) / this.scale);
                this.reelsContainer.x = this.reelsContainer.width / 2;
                this.reelsContainer.y = (centerScreenPoint.y) + (160 * this.scale);
            }
        }
        else {
            this.reelsContainer.scale.set(1);
            const centerScreenPoint = new Point((this.windowSize().width / 2) / this.scale, (this.windowSize().height / 2) / this.scale);
            this.reelsContainer.x = (centerScreenPoint.x);
            this.reelsContainer.y = (centerScreenPoint.y);
        }
    }
    windowSize() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }
    getScale() {
        var _a;
        if (!window.PLATFORM.isMobile) {
            const mapMaxSize = {
                '1280': 1024,
                '1920': 1280,
                '2560': 1440,
                '3840': 2800,
            };
            const mapKey = (_a = Object.keys(mapMaxSize).find(key => {
                return Number(key) >= this.windowSize().width;
            })) !== null && _a !== void 0 ? _a : '1280';
            const windowMaxWidth = Math.min(this.windowSize().width, mapMaxSize[mapKey]);
            const containerMaxWidth = this.width;
            const scale = //(containerMaxWidth) / 1280
             parseFloat((windowMaxWidth / containerMaxWidth).toFixed(2));
            return scale;
        }
        if (window.PLATFORM.isLandscape) {
            const min = Math.min(this.width, this.height);
            const scale = Math.min(this.windowSize().height, this.windowSize().width);
            return parseFloat(((min) > scale
                ? scale / (min)
                : (min) / scale).toFixed(2));
        }
        const max = Math.max(this.width, this.height);
        const scale = Math.min(this.windowSize().height, this.windowSize().width);
        return parseFloat(((max) > scale
            ? scale / (max)
            : (max) / scale).toFixed(2));
    }
    removeBonusMultiplier() {
        if (this.counter) {
            this.counter.container.removeFromParent();
        }
    }
    triggerBonusMultiplier(reels) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            try {
                const sliceButton = (_a = Game.Engine.ACTIONS_CONTAINER) === null || _a === void 0 ? void 0 : _a.getButtonByAction(CONSTANTS.BUTTON_ACTIONS.SLICE);
                if (sliceButton) {
                    const buttonPosition = this.ActionsWrapper.sliceButton.container.getGlobalPosition();
                    const position = {
                        x: buttonPosition.x / this.scale,
                        y: buttonPosition.y / this.scale,
                    };
                    sliceButton.setDisabled();
                    (_b = Game.Engine.ACTIONS_CONTAINER) === null || _b === void 0 ? void 0 : _b.getButtonByAction(CONSTANTS.BUTTON_ACTIONS.SPIN).setDisabled();
                    (_c = Game.Engine.ACTIONS_CONTAINER) === null || _c === void 0 ? void 0 : _c.getButtonByAction(CONSTANTS.BUTTON_ACTIONS.AUTO_SPIN).setDisabled();
                    const destinationPosition = this.reelsContainer.reelsView.getGlobalPosition();
                    const container = this.reelsContainer.reelsView.getBounds();
                    const destination = {
                        x: (destinationPosition.x / this.scale) + (container.width / this.scale),
                        y: destinationPosition.y / this.scale,
                    };
                    this.counter = new BonusMultiplier({
                        sprite: '/textures/animations/scarab.json',
                        width: 200,
                        height: 200,
                        x: position.x,
                        y: position.y,
                        zIndex: 999,
                    });
                    Game.Engine.updateGameData('multiplier', reels.multiplier || 1);
                    this.counter.appendTo(this.container);
                    yield this.counter.animate({
                        x: destination.x,
                        y: destination.y,
                    }, reels.multiplier || 1);
                    Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.GLOBAL_SOCKET_EVENT, {
                        action: CONSTANTS.APPLICATION_EVENTS.SOCKET_EVENTS.SPIN,
                        payload: reels
                    });
                }
            }
            catch (e) {
                console.log("ERROR:::", e);
            }
        });
    }
    onWin(event) {
        const payload = event.payload;
        const lines = Transformers.ObjectToWinnings(payload);
        if (this.winLinesRef) {
            this.winLinesRef.drawLines(lines);
        }
    }
    onWinPresent(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const modal = WinModal.presentModal(payload.type, {
                hideTimeoutSeconds: 3,
                hideOnBackdropClick: true,
                topOffset: 100,
                scale: this.scale,
                onShow: () => {
                    Game.Engine.Emitter.emit(CONSTANTS.APPLICATION_EVENTS.SOUND_EFFECTS_EVENTS.WIN_MODAL);
                },
                onHide: () => {
                    Game.Engine.setGameMode(payload.nextState);
                }
            });
            if (!modal) {
                return;
            }
            yield modal.show();
        });
    }
    handleModeChange({ mode, payload }) {
        console.log('handle mode', mode);
        if (mode === 'win-present') {
            this.onWinPresent(payload);
            return;
        }
        if (mode === 'free-spins') {
            // this.onFreeSpins()
        }
    }
    winLinesDraw(event) {
        if (this.winLinesRef) {
            return;
        }
        this.winLinesRef = new WinningLines([]);
        const testContainer = new Container();
        testContainer.pivot.set();
        testContainer.addChild(this.winLinesRef.container);
        testContainer.scale.set(1);
        this.container.addChild(testContainer);
    }
    registerEventListeners() {
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ANIMATION_EVENTS.HIDE_MULTIPLIER, this.removeBonusMultiplier.bind(this));
        Game.Engine.SOCKET.on(MESSAGES.summonScarabei, this.triggerBonusMultiplier.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.BASE_EVENTS.WIN, this.onWin.bind(this));
        // Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, this.presentSettings.bind(this));
        Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.GAME_MODE_CHANGE, this.handleModeChange.bind(this));
        // Game.Engine.Emitter.on(CONSTANTS.APPLICATION_EVENTS.ACTION_EVENTS, this.winLinesDraw.bind(this))
        Game.Engine.SOCKET.on(MESSAGES.spin, this.winLinesDraw.bind(this));
    }
    setGameId(id) {
        this.gameIdLabel.text = `Round: ${id}`;
        this.positionGameIdLabel();
    }
    resize(width, height) {
        this.scale = this.getScale();
        this.container.scale.set(this.scale);
        Game.Engine.REELS.forEach((reel, reelIndex) => {
            reel.symbols.forEach((symbol, symbolIndex) => {
                symbol.resize();
            });
        });
        this.reelsContainer.resize();
        this.positionReelsContainer();
        // this.reelsContainer.pivot.set(
        //   this.reelsContainer.width / 2,
        //   this.reelsContainer.height / 2
        // );
        this.positionActions();
        this.positionActionsContainer();
        this.positionBackground();
        this.positionLogo();
        this.positionGameIdLabel();
    }
}
